<!-- 能力详情 -->
<template>
    <m-loading color="#1989fa" :loading="spinning">
        <div id="abilityDetails" ref="abilityDetails">
            <div class="title-label">
                <label-title title="学习力得分">
                    <img slot="icon" src="@/assets/icon/i_brain.png" alt="">
                </label-title>
                <span>测评时间：
                    {{ scoreData.evaluationTime && FormatDate(scoreData.evaluationTime, 'YYYY年MM月DD日') }}
                </span>
            </div>

            <div class="score"
                 :style="gradeProgress.backColor && `background-color: ${gradeProgress.backColor};background-image: url('${gradeProgress.back}');`">
                <div class="grade" :style="gradeProgress.backColor && `background: ${gradeProgress.color};`">
                    {{ scoreData.level }}
                </div>
                <div class="grade-progress"
                     :style="gradeProgress.backColor && `background-color: ${gradeProgress.color};`">
                    <div ref="progress" class="ant-progress"></div>
                    <span class="p-number">
                    {{ scoreData.studyScore }}
                    <i v-if="scoreData.isPromote">&uarr;{{ scoreData.differenceScore }}</i>
                </span>
                    <span class="p-name">得分</span>
                </div>
                <div class="fraction">
                    <div>
                        <span>100</span>
                        同龄均分
                    </div>
                    <div class="divider"></div>
                    <div>
                        <span><i>前</i>{{ scoreData.studyPercent || 0 }}%</span>
                        排名
                    </div>
                </div>
            </div>

            <label-title title="能力图谱" v-show="chartData.length > 2">
                <img slot="icon" src="@/assets/icon/i_brain.png" alt="">
            </label-title>

            <div class="chart" v-show="chartData.length > 2">
                <div ref="radar" id="radar"></div>
                <p>综合各个能力的表现，{{ $route.query.userName }}的学习能力属于 </p>
                <h4>{{ scoreData.evaluation }}</h4>
            </div>

            <label-title title="学习力分析">
                <img slot="icon" src="@/assets/icon/i_brain.png" alt="">
            </label-title>

            <div class="ball" v-show="chartData.length > 1">
                <div class="ball-left" v-show="chartData.filter(f => f.score >= averageScore).length">
                    <div ref="ballLeft" id="ball-left-box"></div>
                    <h4>相对擅长</h4>
                </div>
                <div class="ball-right" v-show="chartData.filter(f => f.score < averageScore).length">
                    <div ref="ballRight" id="ball-right-box"></div>
                    <h4>相对薄弱</h4>
                </div>
            </div>
            <ul class="progress">
                <li v-for="item in ability" @click="abilityItemClick(item)">
                    <h3>{{ item.name }}</h3>
                    <div>
                        <div class="line">
                            <img :style="`left: calc(${item.firstScore - 50}% - 3.5px);`"
                                 v-if="item.firstScore > 0 && !scoreData.isFirstEvaluation" class="line-i" src="@/assets/academic/aca-i.png"/>
                            <div></div>
                            <div :style="`width: ${item.score - 50}%;${item.style}`"></div>
                        </div>
                        <span>
                            {{ item.score }}/150
                            <em :style="!item.backColors && `opacity: 0;`"><van-icon name="arrow"/></em>
                        </span>
                    </div>
                </li>
            </ul>
        </div>
    </m-loading>
</template>

<script>
import Apis from "@/requests/api";
import {FormatMS, FormatDate} from "@/utils/methods";

export default {
    name: "AbilityDetails",
    data() {
        return {
            FormatMS,
            FormatDate,
            spinning: true,
            organizationUserId: this.$route.query.organizationUserId,
            userCourseId: this.$route.query.userCourseId,
            userInfo: {},
            // 能力数值
            ability: [],
            chartData: [],
            grade: [
                {
                    name: "S",
                    text: "全面突出",
                    color: "#179FFC",
                    backColor: "rgba(23, 159, 252, 0.05)",
                    back: require("@/assets/academic/back/asd-back1.png")
                },
                {
                    name: "A",
                    text: "平衡发展",
                    color: "#31D0E6",
                    backColor: "rgba(49, 208, 230, 0.05)",
                    back: require("@/assets/academic/back/asd-back2.png")
                },
                {
                    name: "B",
                    text: "亟待提升",
                    color: "#2CD1B4",
                    backColor: "rgba(44, 209, 180, 0.05)",
                    back: require("@/assets/academic/back/asd-back3.png")
                },
                {
                    name: "C",
                    text: "有待改进",
                    color: "#FFD703",
                    backColor: "rgba(255, 215, 3, 0.05)",
                    back: require("@/assets/academic/back/asd-back4.png")
                },
                {
                    name: "D",
                    text: "不均衡型",
                    color: "#FF655C",
                    backColor: "rgba(255, 101, 92, 0.05)",
                    back: require("@/assets/academic/back/asd-back5.png")
                },
            ],
            gradeProgress: {},
            courseLoading: false,
            scoreData: {},
            averageScore: 0
        }
    },
    watch: {
        $route(to) {
            document.getElementById("app").scrollTop = 0;
            this.userCourseId = to.query.userCourseId;
            if (this.$options.mounted[1]) {
                this.$options.mounted[1].call(this);
            } else {
                this.$options.mounted[0].call(this);
            }
        }
    },
    mounted() {
        document.title = "能力详情";
        console.log(this.$route.query);
        if (!this.$store.getters["user/getToken"]()){
            this.$store.commit("user/setToken", this.$route.query.token);
        }
        this.initData();
        this.getAbilityPortraitData();
    },
    beforeDestroy() {
        document.getElementById("app").onscroll = null;
    },
    methods: {
        abilityItemClick(val) {
            if (!val.backColors) {
                return
            }
            this.$router.push({
                path: "abilityAnalysis",
                query: {
                    type: val.name,
                    organizationUserId: this.organizationUserId,
                    userCourseId: this.userCourseId,
                }
            })
        },
        getAbilityPortraitData() {
            this.spinning = true;
            this.$http.get(Apis.Kindergarten.getUserCourseAbilityPortrait, {
                organizationUserId: this.organizationUserId,
                userCourseId: this.userCourseId,
            }).then(res => {
                this.spinning = false;
                if (res.status) {
                    if (!res.data) {
                        return
                    }
                    const Abilitys = [
                        {
                            name: "注意力",
                            backColors: ["#63FFAB", "#09C5AF", "#25E8F4", "#5BBFFF"],
                            style: "background: linear-gradient(90deg, #63FFAB 0%, #09C5AF 100.06%), linear-gradient(90deg, #25E8F4 0%, #5BBFFF 100%);"
                        },
                        {
                            name: "自控力",
                            backColors: ["#FF93E7", "#E611A4", "#25E8F4", "#5BBFFF"],
                            style: "background: linear-gradient(90deg, #FF93E7 0%, #E611A4 97.67%), linear-gradient(90deg, #25E8F4 0%, #5BBFFF 100%);"
                        },
                        {
                            name: "反应力",
                            backColors: ["#FF928B", "#FF3D3D", "#25E8F4", "#5BBFFF"],
                            style: "background: linear-gradient(90deg, #FF928B 0%, #FF3D3D 99.96%), linear-gradient(90deg, #25E8F4 0%, #5BBFFF 100%);"
                        },
                        {
                            name: "思维力",
                            backColors: ["#25E8F4", "#5BBFFF", "#25E8F4", "#5BBFFF"],
                            style: "background: linear-gradient(90deg, #25E8F4 0%, #5BBFFF 100%);"
                        },
                        {
                            name: "记忆力",
                            backColors: ["#FCDE76", "#FF8324", "#25E8F4", "#5BBFFF"],
                            style: "background: linear-gradient(90deg, #FCDE76 0%, #FF8324 99.96%), linear-gradient(90deg, #25E8F4 0%, #5BBFFF 100%);"
                        },
                    ];
                    this.scoreData = res.data;
                    this.gradeProgress = this.grade.filter(f => f.name === (res.data.level[0] || "D"))[0];
                    this.ability = res.data.abilityScoreInfo.map(m => {
                        let ability = Abilitys.filter(f => f.name === m.name)[0];
                        if (ability) {
                            return {
                                ...ability,
                                ...m
                            }
                        }
                        return {
                            style: "background: #25e8f4",
                            ...m
                        }
                    });
                    this.chartData = this.ability.filter(f => f.backColors);
                    this.averageScore = this.chartData.reduce((a, b) => a + b.score, 0) / this.chartData.length;
                    this.$nextTick(() => {
                        setTimeout(() => {
                            this.initProgress(res.data.studyScore);
                            this.initRadar();
                            this.initCircular();
                        }, 1000)
                    })
                } else {
                    this.$toast(res.message);
                }
            })
        },
        initProgress(data) {
            const option = {
                series: [
                    {
                        type: 'gauge',
                        radius: "70%",
                        min: 50,
                        max: 150,
                        splitNumber: 6,
                        center: ["50%", "50%"],
                        startAngle: 220,
                        endAngle: -40,
                        pointer: {
                            show: false,
                            showAbove: false
                        },
                        progress: {
                            show: true,
                            roundCap: true,
                            width: 8,
                            itemStyle: {
                                color: "#fff"
                            }
                        },
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 8,
                                //color: "#0f0"
                            }
                        },
                        axisTick: {
                            show: false,
                        },
                        splitLine: {
                            show: false
                        },
                        axisLabel: {
                            show: false
                        },
                        title: {
                            show: false
                        },
                        detail: {
                            show: false,
                        },
                        data: [
                            {
                                value: data
                            }
                        ]
                    }
                ]
            };
            const myChart = this.$echarts.init(this.$refs.progress);
            myChart.setOption(option);
        },
        initData() {
            this.historyCourse = {
                page: 1,
                limit: 5,
                total: 0,
                data: []
            };
            this.ability = [];
            this.grade = [
                {
                    name: "S",
                    text: "全面突出",
                    color: "#179FFC",
                    backColor: "rgba(23, 159, 252, 0.05)",
                    back: require("@/assets/academic/back/asd-back1.png")
                },
                {
                    name: "A",
                    text: "平衡发展",
                    color: "#31D0E6",
                    backColor: "rgba(49, 208, 230, 0.05)",
                    back: require("@/assets/academic/back/asd-back2.png")
                },
                {
                    name: "B",
                    text: "亟待提升",
                    color: "#2CD1B4",
                    backColor: "rgba(44, 209, 180, 0.05)",
                    back: require("@/assets/academic/back/asd-back3.png")
                },
                {
                    name: "C",
                    text: "有待改进",
                    color: "#FFD703",
                    backColor: "rgba(255, 215, 3, 0.05)",
                    back: require("@/assets/academic/back/asd-back4.png")
                },
                {
                    name: "D",
                    text: "不均衡型",
                    color: "#FF655C",
                    backColor: "rgba(255, 101, 92, 0.05)",
                    back: require("@/assets/academic/back/asd-back5.png")
                },
            ];
            this.gradeProgress = {};
            this.courseLoading = false;
            this.scoreData = {};
        },
        initRadar() {
            const indicator = this.chartData.map(m => ({name: m.name, max: 150, min: 50}));
            const now = this.chartData.map(m => m.score < 50 ? 50 : m.score);
            const old = this.chartData.map(m => m.firstScore < 50 ? 50 : m.firstScore);
            let index = 0;
            const option = {
                legend: {
                    bottom: 0,
                    itemWidth: 16,
                    itemHeight: 2
                },
                color: ["#17A4FD", "#FF655C"],
                radar: {
                    //shape: 'circle',
                    center: ["50%", "45%"],
                    radius: "64%",
                    splitNumber: 4,
                    indicator: indicator,
                    axisName: {
                        color: "#000",
                        formatter: function (value) {
                            let num = now[index] - old[index];
                            index += 1;
                            if (num > 0) {
                                return `{a|${value}}{b|↑${num}}`
                            }
                            return `{a|${value}}`
                        },
                        rich: {
                            b: {
                                color: "#FF655C",
                                padding: [0, 0, 0, 5],
                            },
                        }
                    },
                    splitArea: {
                        show: false
                    }
                },
                series: [
                    {
                        type: 'radar',
                        symbolSize: 0,
                        data: [
                            {
                                value: now,
                                name: '本次'
                            },
                            {
                                value: old,
                                name: '首次'
                            }
                        ]
                    }
                ]
            };
            const myChart = this.$echarts.init(this.$refs.radar);
            myChart.setOption(option);
        },
        initCircular() {
            const size = [64, 56, 54, 52, 42];
            const data = this.chartData.sort((a, b) => b.score - a.score).map((m, i) => {
                return {
                    name: m.name,
                    value: m.score,
                    symbolSize: size[i],
                    itemStyle: {
                        color: new this.$echarts.graphic.LinearGradient(
                            1, 0, 0, 0, [
                                {offset: 1, color: m.backColors[0]},
                                {offset: 0, color: m.backColors[1]}
                            ]
                        )
                    },
                    label: {
                        show: true,
                        color: "#fff"
                    }
                }
            });
            this.$echarts.init(this.$refs.ballLeft).setOption({
                series: [
                    {
                        type: 'graph',
                        layout: 'force',
                        animation: false,
                        //roam: true,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        data: data.filter(f => f.value >= this.averageScore),
                        force: {
                            //initLayout: 'circular',
                            gravity: 0.15,
                            repulsion: 100,
                            edgeLength: 10,
                            friction: 0.2
                        },
                    }
                ]
            });
            this.$echarts.init(this.$refs.ballRight).setOption({
                animation: false,
                series: [
                    {
                        type: 'graph',
                        layout: 'force',
                        animation: false,
                        //roam: true,
                        left: 0,
                        right: 0,
                        top: 0,
                        bottom: 0,
                        data: data.filter(f => f.value < this.averageScore),
                        force: {
                            //initLayout: 'circular',
                            gravity: 0.15,
                            repulsion: 100,
                            edgeLength: 10,
                            friction: 0.2
                        },
                    }
                ]
            });
        }
    }
}
</script>

<style scoped lang="less">
#abilityDetails {
    width: 100%;
    padding: 20px;
}

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.label-title {
    font-size: 20px;
    font-weight: 600;

    img {
        width: 24px;
        height: 24px;
    }
}

.title-label {
    display: flex;
    align-items: flex-end;

    span {
        color: #434343;
        font-size: 12px;
        opacity: .5;
        white-space: nowrap;
    }
}

.score {
    height: 274px;
    border-radius: 16px;
    background-size: cover;
    position: relative;
    margin: 13px 0 33px;

    .grade {
        font-weight: 700;
        font-size: 24px;
        width: 64px;
        height: 40px;
        border-radius: 20px;
        line-height: 40px;
        text-align: center;
        color: #fff;
        position: absolute;
        top: 11px;
        right: 11px;
    }

    .grade-progress {
        background-image: url("../../assets/academic/aca-progress.png");
        background-size: 100% 100%;
        width: 124px;
        height: 124px;
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 50px;
        transform: translateX(-50%);
    }

    .ant-progress {
        width: 124%;
        height: 125%;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    /deep/ path:first-child {
        stroke: transparent !important;
    }

    .p-number {
        position: absolute;
        top: 42px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 26px;
        color: #ffffff;
        display: flex;
        align-items: flex-end;
        white-space: nowrap;

        & > i {
            font-size: 12px;
            margin-left: 4px;
            margin-bottom: 5px;
        }
    }

    .p-name {
        position: absolute;
        bottom: 15px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 13px;
        color: #ffffff;
    }

    .fraction {
        width: 280px;
        padding: 0 40px;
        position: absolute;
        left: 50%;
        bottom: 10px;
        transform: translateX(-50%);
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 12px;
        color: #8C8C8C;
        margin-top: 45px;
        text-align: center;

        span {
            display: block;
            font-size: 16px;
            color: #000000;
        }

        i {
            font-size: 14px;
        }

        .ant-divider {
            background: #8C8C8C;
        }
    }
}

.divider {
    width: 1px;
    height: 15px;
    background-color: #8C8C8C;
}

.chart {
    margin: 23px 0 33px;
    text-align: center;

    #radar {
        width: 100%;
        height: 235px;
    }

    p {
        color: #8C8C8C;
        font-size: 10px;
        line-height: 14px;
        margin-top: 12px;
    }

    h4 {
        color: #FF655C;
        font-size: 16px;
        margin-top: 10px;
    }
}

.ball {
    margin: 15px 0;
    text-align: center;
    display: flex;
    justify-content: center;

    h4 {
        color: #363F44;
        font-size: 16px;
    }

    .ball-left, .ball-right {
        width: 100%;
    }

    #ball-left-box, #ball-right-box {
        height: 155px;
    }
}

.progress {
    margin-top: 30px;

    li {
        height: 68px;
        border: 1px solid #D8D8D8;
        border-radius: 8px;
        margin-bottom: 16px;
        padding: 11px 16px;
    }

    h3 {
        font-size: 16px;
        color: #000;
        font-weight: 600;
    }

    span {
        font-size: 12px;
        color: #000;
        flex-shrink: 0;
    }

    li > div {
        display: flex;
        align-items: center;
        margin-top: 5px;
    }

    .line {
        height: 16px;
        flex-grow: 1;
        margin-right: 7px;
        position: relative;
    }

    .line div {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: #F5F5F5;
        border-radius: 4px;
    }

    .line-i {
        position: absolute;
        left: -2px;
        top: -3px;
        z-index: 3;
    }
}

.history {
    margin-top: 23px;

    .history-item {
        height: 99px;
        margin-bottom: 30px;
        display: flex;
        background: rgba(23, 159, 252, 0.05);
        border-radius: 16px;

        .left {
            width: 96px;
            height: 100%;
            flex-shrink: 0;
            position: relative;
            text-align: center;
            color: #ffffff;

            h2 {
                font-weight: 700;
                font-size: 24px;
                color: #ffffff;
                margin: 24px 0 6px;
            }

            span {
                display: inline-block;
                width: 60px;
                height: 22px;
                line-height: 22px;
                background: rgba(255, 255, 255, 0.3);
                border: 1px solid #FFFFFF;
                border-radius: 6px;
                font-size: 12px;
            }
        }

        .left > div {
            width: 100%;
            height: 113px;
            position: absolute;
            left: 0;
            top: -7px;
            background: #179FFC;
            border-radius: 16px;
        }

        .right {
            height: 100%;
            flex-grow: 1;
            padding: 10px 14px;

            h3 {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                height: 40px;
                word-break: break-all;
                text-overflow: ellipsis;
                overflow: hidden;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            p {
                font-size: 12px;
                color: #8C8C8C;
                margin-top: 2px;
            }

            span:last-child {
                margin-left: 15px;
            }
        }
    }

    .more {
        color: #8C8C8C;
        font-size: 12px;
        opacity: 0.8;
        text-align: center;
    }
}
</style>